import axios from 'axios';
let API = "https://brandinit-backend-d3fa93ff1ec4.herokuapp.com/";

export default {
    post(data, to, u = null) {
        return axios.request({
            method: 'POST',
            url: API+to,
            headers: u ? u : {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    put(data, to, u = null) {
        return axios.request({
            method: 'PUT',
            url: API+to,
            headers: u ? u : {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    get(to, u = null) {
        return axios.request({
            method: 'GET',
            url: API+to,
            headers: u ? u : {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        });
    },
};