import Container from './Container';
import { withRouter } from "react-router-dom";

function Tbody (p) {
    return (
        <tr>
            <td>
                <div className='pdc'>
                    <div className='noi'>
                        <b>New sale</b>
                        <span>50% off for 1 product</span>
                    </div>
                </div>
            </td>
            <td><div className='pdc'><div className='noi'><b>{p.alt ? "Coupon" : "Sale"}</b>{p.alt ? <span>Code: ASDFG</span> : null}</div></div></td>
            <td><div className='pdc'><div className='noi'><b>1/50</b></div></div></td>
            <td><div className='pdc'><div className='noi'><b>25/01/2024</b></div></div></td>
            <td><div className='pdc'><div className='noi'><b>Active</b></div></div></td>
        </tr>
    )
}

function DTable () {
    return (
        <section className="section dashboard">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <table className="table b-table">
                                        <thead>
                                            <tr>
                                                <th>Details</th>
                                                <th>Type</th>
                                                <th>Redeemed</th>
                                                <th>Expires on</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[...Array(25)].map((e, i) => <Tbody key={'trow-'+i} alt={i>1} />)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function Discounts (props) {
  return (
    <Container page="discounts">
        <div className="pagetitle">
            <div className="navrw">
                <h1>Discounts</h1>
            </div>
            <div>
                <a className="btn btn-success" href="coupons"><span>Add new discount</span></a>
            </div>
        </div>
        <div className='filter-section'>
            <p>Add a discount for any number of your products, run a sale for your entire store, or offer freebies.</p>
        </div>
        <DTable />
    </Container>
  );
}

export default Discounts;