import Container from './Container';
import { withRouter } from "react-router-dom";

function NewCoupon (props) {
  return (
    <Container page="coupons">
        <div className="pagetitle">
            <div className="navrw">
                <h1>New Coupon</h1>
            </div>
        </div>

        <div className='row'>
            <div className='col-md-8'>
                <div className='sub-section'>
                    <div className="form-group">
                        <label><b>Name</b></label>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Name" />
                            <span className="btn-oo">Customers won’t see this</span>
                        </div>
                    </div>
                </div>

                <div className='sub-section'>
                    <label><b>What coupon code should customers use at checkout?</b></label>
                    <div className="form-group">
                        <label>Coupon code</label>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Coupon code" />
                        </div>
                    </div>
                </div>

                <div className='sub-section'>
                    <label><b>What type of discount would you like to create?</b></label>
                    <div className="form-group">
                        <label>Choose type</label>
                        <div className="input-group mb-3">
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <select className='form-control'><option>Percent</option></select>
                                    </div>
                                    <div className='col-md-6 flx-b'>
                                        <input type="number" className="form-control" placeholder="Value" defaultValue={50} />
                                        <div className='perc-1'><span>%</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sub-section'>
                    <label><b>When can a customer redeem this coupon?</b></label>
                    <div className="form-group">
                        <div className='col-lg-12'>
                            <div className='row'>
                                <label className='col-md-4'>Start date</label>
                                <label className='col-md-4'>End date</label>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <input type="date" className="form-control" />
                                    </div>
                                    <div className='col-md-4'>
                                        <input type="date" className="form-control" />
                                    </div>
                                    <div className='col-md-4 flexc'>
                                        <div className="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" /><span className="skk">No expiration</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sub-section'>
                    <label><b>What is the maximum number of times that this coupon can be redeemed across all customers?</b></label>
                    <div className="form-group">
                        <label>Purchase limit</label>
                        <div className="input-group mb-3">
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <input type="number" className="form-control" placeholder="Value" defaultValue={5} />
                                    </div>
                                    <div className='col-md-4 flexc'>
                                        <div className="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" /><span className="skk">No expiration</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sub-section'>
                    <label><b>Which products are eligible for the discount?</b></label>
                    <div className="form-group">
                        <div className="input-group mb-3">
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <select className="form-control">
                                            <option>Search product by name</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='sub-section'>
                    <div className="form-group">
                        <button className='btn btn-success btf'>Create discount</button>
                    </div>
                    <div className="form-group mtp">
                        <div className="form-check form-switch">
                            <input class="form-check-input" type="checkbox" defaultChecked={true} /><span className="skk">Active</span>
                        </div>
                    </div>
                </div>
                <div className='sub-section balt'>
                    <div className="form-group">
                        <b>Helpful info about coupons</b>
                        <ul>
                            <li>Discounts don't add up or combine.</li>
                            <li>Coupons for subscriptions will set the recurring price.</li>
                            <li>Coupon codes are not case sensitive.</li>
                            <li>Coupons can't be applied to shipping costs.</li>
                            <li>Upsells are not affected by coupons.</li>
                        </ul>
                        <br />
                        <b>Tips</b>
                        <span>Notify customers of your new coupon!</span>
                    </div>
                </div>
            </div>
        </div>

    </Container>
  );
}

export default NewCoupon;