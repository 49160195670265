import Container from './Container';
import { withRouter } from "react-router-dom";

function Coupons (props) {
  return (
    <Container page="coupons">
        <div className="pagetitle">
            <div className="navrw">
                <h1>Coupons & Sales</h1>
            </div>
        </div>
        <div className="filter-section mt0"><p>Add a discount for any number of your products, run a sale for your entire store,<br />or offer freebies.</p></div>

        <div className='coupon-div'>
            <a href='/new_coupon'>
                <span>Coupon</span>
            </a>
            <a href='/new_sale'>
                <span>Sale</span>
            </a>
        </div>

    </Container>
  );
}

export default Coupons;