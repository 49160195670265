import $ from 'jquery';
import React, { Component } from 'react';
import '../../App.scss';
// import svgCanvas from '@svgedit/svgcanvas'
import logo from '../../Assets/Images/logo.svg';

class CreateLogo extends Component {

  constructor(props) {
    super(props);

    this.state = {
        image: null
    };
  }

  render () {
    let { image } = this.state;
    return (
      <div className="App">
        <header className="App-header wel">
          <img src={logo} className='App-logo' />
          <div></div>
        </header>
        <main className='App-body container main-body cr'>
          <div className='pp-body cn'>
              <h1 className='mainh'>Create your logo</h1>
              <div className='gsv-cover ulogo'>
                <svgCanvas />
              </div>
          </div>
        </main>
      </div>
    );
  }
}

export default CreateLogo;