import React, { Component } from 'react';
import $ from "jquery";
import '../../App.scss';
import Api from '../../Api.js';
import logo from '../../Assets/Images/logo.svg';
import rpa from '../../Assets/Images/rpa.png';

class ResetPassword extends Component {

  constructor(props) {
    super(props);
    
    let fullForm = this.props.history.location.pathname.replace("/", "").toLowerCase() == "resetpassword";
    let get_data = fullForm && this.props.history.location.search.length ? this.props.history.location.search.split("&") : "";

    let email = get_data.length > 1 ? get_data[0].replace("?email=", "") : "";
    let token = get_data.length > 1 ? get_data[1].replace("token=", "") : "";

    this.state = {
      eye: false,
      eye1: false,
      pop: false,
      fullForm,
      emsg: "",
      email: email,
      token: token,
      smsg: <strong>We sent you a token,<br />please check your email</strong>,
      password: "",
      confirmPassword: "",
      mode: "",
      loading: false
    };
  }
  
  async componentDidMount () {}

  submit = async () => {
    let { email, token, password, confirmPassword, fullForm } = this.state;
    let eRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(email.length && email.match(eRegx)) {
      this.setState({loading: true, emsg: ""});
      if(fullForm) {
        if(password.length >= 8) {
          if(password == confirmPassword) {
            if(token) {
              await Api.post({ email, token, password, confirmPassword }, "api/Auth/resetPassword")
              .then((resp) => {
                this.setState({pop: true, smsg: <strong style={{color: "#12b196"}}>Updated successfully</strong>});
                setTimeout(() => {
                  window.location.href = "/sign-in";
                }, 2500);
              }).catch((obj) => {
                let errors = obj.response.data.errors;
                if(errors) {
                    let efld = Object.keys(errors)[0];
                    let emsg = errors[efld][0];
                    this.setState({emsg, loading: false});
                } else {
                  this.setState({emsg: (obj.response.data?.message || "An error occurred"), loading: false});
                }
              });
            } else {
              this.setState({emsg: "Token cannot be empty", loading: false});
            }
          } else {
            this.setState({emsg: "Passwords do not match", loading: false});
          }
        } else {
          this.setState({emsg: "Password should be minimum eight characters", loading: false});
        }
      } else {
        await Api.post({ email, url: "https://app.brandinit.com" }, "api/Auth/forgotPassword")
        .then((resp) => {
          this.setState({pop: true});
          // setTimeout(() => { window.location.href = "/new-password?email="+email; }, 2500);
        }).catch((obj) => {
          let errors = obj.response.data.errors;
          if(errors) {
              let efld = Object.keys(errors)[0];
              let emsg = errors[efld][0];
              this.setState({emsg, loading: false});
          } else {
            this.setState({emsg: (obj.response.data?.message || "An error occurred"), loading: false});
          }
        });
      }
    } else {
      this.setState({emsg: "Invalid email address", loading: false});
    }
  }

  handleInputChange = (e) => {
      const { name, value } = e.target;
      this.setState({
          [name]: value.trim()
      });
  };

  render () {
    let { emsg, smsg, eye, eye1, email, token, loading, pop, password, confirmPassword, fullForm } = this.state;
      return (
        <div className="App">
          <header className="App-header container">
            <a className='logo-hl' href="https://brandinit.com" target="_blank">
              <img src={logo} className='App-logo' />
            </a>
            <div></div>
          </header>
          
          {pop ? <div className='reset-pop-up' onClick={(e) => {
              if($(e.target).hasClass("reset-pop-up")) {
                  this.setState({pop: false});
              }
          }}>
            <div className='reset-pop'>
              <img src={rpa} alt='' />
              {smsg}
            </div>
          </div> : null}
          <main className='App-body container'>
            <div className='rf-sect'>
                <form action='' onSubmit="return false;" className='rest-form'>
                    <h1 className='mainh tcent'>{fullForm ? "Create new password" : "Reset Password"}</h1>
                    {fullForm ? null :
                    <p className='tcent'>Type in your email address below and we'll send you an email with instructions on how to create a new password</p>}
                    <div className='rest-rect'>
                        {fullForm ? <>
                          <div className="form-group">
                            <label>Email address</label>
                            <input type="email" name='email' onChange={this.handleInputChange} defaultValue={email} className="form-control" placeholder="yourname@email.com" />
                          </div>
                          {/* <div className="form-group">
                            <label>Token</label>
                            <input type="text" name='token' onChange={this.handleInputChange} className="form-control" placeholder="Enter the token sent to your mail" />
                          </div> */}
                          <div className="form-group" style={{position: "relative"}}>
                              <label for="">Password</label>
                              <input type={eye ? "text" : "password"} name='password' onChange={this.handleInputChange} className="form-control" id="" placeholder="Password" />
                              <span className='eye-tog' onClick={() => { this.setState({eye: !eye}); }}>
                              {eye ? <i class="ri-eye-line"></i> : <i class="ri-eye-off-line"></i>}
                              </span>
                          </div>
                          <div className="form-group" style={{position: "relative"}}>
                              <label for="">Confirm password</label>
                              <input type={eye1 ? "text" : "password"} name='confirmPassword' onChange={this.handleInputChange} className="form-control" id="" placeholder="Password" />
                              <span className='eye-tog' onClick={() => { this.setState({eye1: !eye1}); }}>
                              {eye1 ? <i class="ri-eye-line"></i> : <i class="ri-eye-off-line"></i>}
                              </span>
                          </div>
                        </> : <>
                        <div className="form-group">
                          <h4 className='tcent'>Enter your email address</h4>
                          <input type="email" name='email' onChange={this.handleInputChange} defaultValue={email} className="form-control" placeholder="yourname@email.com" />
                        </div>
                        </>}
                        {fullForm ?
                          <button type="button" onClick={this.submit} className="btn btn-primary submit-btn" style={{opacity: loading || !(email.length && token.length && password.length && confirmPassword.length) ? 0.5 : 1, marginTop: "25px", marginBottom: "25px"}}>Update</button>
                        : <button type="button" onClick={this.submit} className="btn btn-primary submit-btn" style={{opacity: loading || !email.length ? 0.5 : 1, marginTop: "25px", marginBottom: "25px"}}>Continue</button>
                      }
                    </div>
                    {emsg.length ? <span className='err-msg alt'>{emsg}</span> : null}
                    <a className='pg cn' href='/sign-in'>Back to Sign In</a>
                </form>
            </div>
          </main>
        </div>
      );
  }

}

export default ResetPassword;