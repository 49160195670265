import '../../App.scss';
import ad from '../../Assets/Images/ad-ex.png';
import he from '../../Assets/Images/he-ci.png';
import ex from '../../Assets/Images/exit.png';
import logo from '../../Assets/Images/logo.svg';


function SideMenu (props) {
  return (
    <>
        <aside id="sidebar" className="sidebar">
            <div className="d-flex align-items-center justify-content-between">
                <a href="https://brandinit.com" target="_blank" className="logo align-items-center">
                    <img src={logo} alt="" className='slogo' />
                </a>
            </div>

            <ul className="sidebar-nav" id="sidebar-nav">
                <li className={"nav-item"+(props.page == "home" ? " _active" : "")}>
                    <a className="nav-link collapsed" href="/home">
                    <i className="ri ri-home-2-line"></i>
                    <span>Home</span>
                    </a>
                </li>
                {/* <li className={"nav-item"+(props.page == "analytics" ? " _active" : "")+" _disab_"}>
                    <a className="nav-link collapsed" href="analytics">
                        <i className="ri ri-pie-chart-2-line"></i>
                        <span>Analytics</span>
                        <button className='pro-btn'>Pro</button>
                    </a>
                </li> */}
                <li className={"nav-item"+(props.page == "__products" ? " _active" : "")}>
                    <a className="nav-link collapsed" data-bs-target="#product-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-box"></i>
                        <span>Products</span>
                        <i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="product-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <a href="/newProduct" className='sub-a'>
                                <span>Add new product</span>
                                <div class="icon">
                                    <i class="ri-add-circle-line"></i>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="products">
                                <span>My products</span>
                            </a>
                        </li>
                    </ul>
                </li>
                {/* <li className={"nav-item"+(props.page == "__orders" ? " _active" : "")}>
                    <a className="nav-link collapsed" data-bs-target="#orders-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-cart3"></i>
                        <span>Orders</span>
                        <i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="orders-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
                        <li>
                            <a href="/orders">
                                <span>Fulfilled</span>
                            </a>
                        </li>
                        <li>
                            <a href="/orders">
                                <span>Unfulfilled</span>
                            </a>
                        </li>
                    </ul>
                </li> */}
                <li className={"nav-item"+(props.page == "earnings" ? " _active" : "")}>
                    <a className="nav-link collapsed" href="/earnings">
                    <i className="bi bi-cash"></i>
                    <span>Earnings</span>
                    </a>
                </li>
                {/* <li className={"nav-item"+(props.page == "customers" ? " _active" : "")}>
                    <a className="nav-link collapsed" href="/customers">
                    <i className="bi bi-people"></i>
                    <span>Customers</span>
                    </a>
                </li> */}
                <li className={"nav-item"+(props.page == "settings" ? " _active" : "")}>
                    <a className="nav-link collapsed" href="/settings">
                    <i className="bi bi-gear"></i>
                    {/* <span>Brand Settings</span> */}
                    <span>Settings</span>
                    </a>
                </li>
                {/* <li className={"nav-item"+(props.page == "__settings" ? " _active" : "")}>
                    <a className="nav-link collapsed" data-bs-target="#settings-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-gear"></i>
                        <span>Store settings</span>
                        <i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="settings-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
                        <li>
                            <a href="/settings">
                                <span>General settings</span>
                            </a>
                        </li>
                        <li>
                            <a href="/email_settings">
                                <span>Email settings</span>
                            </a>
                        </li>
                    </ul>
                </li> */}
                {/* <li className={"nav-item"+(props.page == "discounts" ? " _active" : "")+" _disab_"}>
                    <a className="nav-link collapsed" href="discounts">
                        <i className="bi bi-percent"></i>
                        <span>Discounts</span>
                        <button className='pro-btn'>Pro</button>
                    </a>
                </li> */}
            </ul>
            <div className='sbtab'>
                <div><a href="https://brandinit.co.uk/contact-us/" target="_blank">Need help?</a></div>
                {/* <div className='nh'><img src={he} /><span>Need Help?</span></div> */}
                <div className='nh' onClick={() => {
                    window.location.href = "/sign-out";
                }}><img src={ex} /><span>Sign Out</span></div>
            </div>
        </aside>
        <div className='sidebar-shadow'></div>
    </>
  );
}

export default SideMenu;