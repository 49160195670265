import Container from './Container';
import { withRouter } from "react-router-dom";
import Chart from 'react-apexcharts'

function Tbody () {
    return (
        <tr>
            <td className='cspan2'>Whte Jumper</td>
            <td>200</td>
            <td>£2,000</td>
        </tr>
    )
}

function ATable () {
    return (
        <section className="section dashboard">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <table className="table b-table">
                                        <thead>
                                            <tr>
                                                <th className='cspan2'>Products</th>
                                                <th>Purchaces</th>
                                                <th>Revenue</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[...Array(15)].map((e, i) => <Tbody key={'trow-'+i} />)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function Analytics (props) {
    let cdata =  {
      series: [{
          name: "",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
      }],
      options: {
        chart: {
          height: 350,
          width: window.innerWidth - 480,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: '', // Product Trends by Month
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
      },
    };
  return (
    <Container page="analytics">
        <div className="pagetitle">
            <div className="navrw">
                <h1>Analytics</h1>
            </div>
            <div className='icrow'>
                <div class="iw_fill">
                    <input type="search" placeholder='Search product by name' class="form-control" />
                    <span className='sp_i'><i className='bx bx-search-alt'></i></span>
                </div>
                <div class="iw_fill">
                    <input type="date" placeholder='Select date range' class="form-control" />
                </div>
            </div>
        </div>
        
        <br />
        
        <div className="row">
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-xxl-3 col-md-6">
                        <div className="card info-card _4">
                            <div className="card-body">
                                <h5 className="card-title">Visits</h5>
                                <div className="d-flex align-items-center">
                                    <h6>5,000</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-3 col-md-6">
                        <div className="card info-card _1">
                            <div className="card-body">
                                <h5 className="card-title">Purchases</h5>
                                <div className="d-flex align-items-center">
                                    <h6>200</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-3 col-xl-12">
                        <div className="card info-card _2">
                            <div className="card-body">
                                <h5 className="card-title">Conversions</h5>
                                <div className="d-flex align-items-center">
                                    <h6>26%</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-3 col-xl-12">
                        <div className="card info-card">
                            <div className="card-body">
                                <h5 className="card-title">Revenue</h5>
                                <div className="d-flex align-items-center">
                                    <h6>£8,700</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div className='gsect'>
            <div id="chart">
                <Chart options={cdata.options} series={cdata.series} type="line" height={cdata.options.chart.height} width={cdata.options.chart.width} />
            </div>
        </div>

        <div className='filter-section'>
            <h2>Top Products</h2>
        </div>
        <ATable />
    </Container>
  );
}

export default Analytics;