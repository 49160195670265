import Container from './Container';
import { withRouter } from "react-router-dom";

function Tbody () {
    return (
        <tr>
            <td className='cspan1'>Jean Yawson</td>
            <td className='cspan1'>Jeanyawson@webmail.com</td>
            <td>3</td>
        </tr>
    )
}

function PTable () {
    return (
        <section className="section dashboard">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <table className="table b-table">
                                        <thead>
                                            <tr>
                                                <th className='cspan1'>Name</th>
                                                <th className='cspan1'>Email</th>
                                                <th>Number of order</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[...Array(30)].map((e, i) => <Tbody key={'trow-'+i} />)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function Customers (props) {
  return (
    <Container page="customers">
        <div className="pagetitle">
            <div className="navrw">
                <h1>All Customers</h1>
            </div>
        </div>
        <div className='filter-section'>
            <div class="iw_fill au">
                <input type="search" placeholder='Search customer name/email' class="form-control" />
                <span className='sp_i'><i className='bx bx-search-alt'></i></span>
            </div>
        </div>
        <PTable />
    </Container>
  );
}

export default Customers;