import '../../App.scss';
import logo from '../../Assets/Images/logo.svg';


function PriceSetting () {
  return (
    <div className="App bga">
      <header className="App-header wel">
        <img src={logo} className='App-logo' />
        <div></div>
      </header>
      <main className='App-body container main-body cn full'>
        <h4 className='mainh'>Price Setting</h4>
        
        <div className='gsv-cover fill'>
            <form action='/'>
                <div className="form-group">
                    <label for="">Product name</label>
                    <input type="text" className="form-control" id="" placeholder="Product name" value="My Fancy Product" />
                    {/* <input type="text" className="form-control" id="" placeholder="This product costs" readOnly />
                    <input type="text" className="form-control" id="" placeholder="The Shipping costs" readOnly />
                    <input type="text" className="form-control" id="" placeholder="How much are you charging" readOnly /> */}
                    <div className='fgroup'>
                      <span className='fth'>This product costs</span>
                      <span className='ftd'>£30.00</span>
                    </div>
                    <div className='fgroup'>
                      <span className='fth'>The Shipping costs</span>
                      <span className='ftd'>£30.00</span>
                    </div>
                    <div className='fgroup'>
                      <span className='fth'><b>How much are you charging</b></span>
                      <span className='ftd' style={{background: "#12B196", color: "#fff"}}>£30.00</span>
                    </div>
                </div>
            </form>
        </div>

        <div className='gsv-cover fill alt'>
            <b>Total price</b>
            <strong>£50</strong>
        </div>
        <div className='fright'>
            <a className='btn-next' style={{minWidth: "225px"}} href='/product_created'>Save Product</a>
        </div>
      </main>
    </div>
  );
}

export default PriceSetting;