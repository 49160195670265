import '../../App.scss';
import Api from '../../Api.js';
import React, { Component } from 'react';
import logo from '../../Assets/Images/logo.svg';

function NpMain ({categories}) {
  return (
    <main className='App-body container main-body cr'>
      <div className='pp-body'>
          <h3 className='mainh b2'>Create New Product</h3>
          <div className='cnp-tab'>
          <div className='cnp-rw f'>
            {categories.length ? categories.map((cn, ck) => {
              return (
                <a className={'cnp-it h c'+(ck+1)} href={'/product/'+cn.id}><span>{cn.categoryName}</span></a>
              )
            }) : null}
            {(3-(categories.length%3)) > 0 ? <>
              {(3-(categories.length%3)) == 1 || (3-(categories.length%3)) == 2 ? <div className='cnp-it h t'></div> : null}
              {(3-(categories.length%3)) == 2 ? <div className='cnp-it h t'></div> : null}
            </> : null}
          </div>
          </div>
      </div>
    </main>
  )
}

class NewProduct extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      emsg: "",
      pcat: []
    };
  }
  
  async componentDidMount () {
    let user = window.localStorage.getItem("seller");
        user = JSON.parse(window.localStorage.getItem("seller"));
    await Api.get("api/ProductCategory/getproductcategories")
    .then((resp) => {
      let pcat = resp.data.data.items;
      this.setState({pcat, user});
    }).catch((obj) => {
      if(obj?.response?.data) {
        this.setState({emsg: obj.response.data.message, user});
      }
    });
  }

  render () {
  let props = this.props;
  let { pcat } = this.state;
  let sumrz = props?.summarize || "";

  return sumrz ? <NpMain categories={pcat} /> : (
    <div className="App bga afap">
      <header className="App-header wel">
        <img src={logo} className='App-logo' />
        <div></div>
      </header>
      <NpMain categories={pcat} />
    </div>
  );
  }
}

export default NewProduct;