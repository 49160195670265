import React, { Component } from 'react';
import Api from '../../Api.js';
import '../../App.scss';
import logo from '../../Assets/Images/logo.svg';
import amico from '../../Assets/Images/amico.svg';

// function ProductCreated () {
// }

// export default ProductCreated;

class ProductCreated extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: null,
      _gopg: "guardian"
    };
  }

  errorHandle = (obj) => {
    let emsg = "An error occurred";
    if(obj?.response) {
        emsg = obj.response.data.message || emsg;
        this.setState({emsg, loading: false});
    } else {
        this.setState({emsg, loading: false});
    }
  }

  async componentDidMount () {
    let user = window.localStorage.getItem("seller");
        user = JSON.parse(window.localStorage.getItem("seller"));
    if(user) {
      this.setState({user});
      await Api.get("api/Seller/getSellerBrand", {"Authorization": 'Bearer '+user?.token})
      .then((resp) => {
          let store = resp.data.data;
          if(store.isAbove18) {
            this.setState({_gopg: "home"});
          }
      }).catch((obj) => {
          this.errorHandle(obj);
      });
    } else {
      window.location.href = "/sign-in";
    }
}

  render () {
    let { _gopg } = this.state;

    console.log(_gopg, "========", typeof _gopg);
    return (
      <div className="App bga">
        <header className="App-header wel">
            <a className='logo-hl' href="https://brandinit.com" target="_blank">
              <img src={logo} className='App-logo' />
            </a>
          <div></div>
        </header>
        <main className='App-body container main-body cn full'>
          <img src={amico} alt='' style={{marginBottom: "1em"}} />
          <h1 className='mainh cn'>Congratulations!<br />You have created your product</h1>
          <span style={{margin: "1em", textAlign: "center"}} className='nobr'>Your product is being processed for approval,{_gopg == "guardian" ? <> please fill out <br />your guardian details so you can</> : <></>} check out <br />your professional dashboard.</span>
          <a className='btn-next' style={{minWidth: "225px"}} href={'/'+_gopg}>Continue</a>
        </main>
      </div>
    );
  }
}

export default ProductCreated;