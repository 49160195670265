import '../../App.scss';
import Api from '../../Api.js';
import React, { Component } from 'react';
import logo from '../../Assets/Images/logo.svg';
import c1 from '../../Assets/Images/c1.png';
import c2 from '../../Assets/Images/c2.png';
import c3 from '../../Assets/Images/c3.png';
import c4 from '../../Assets/Images/c4.png';
import c5 from '../../Assets/Images/c5.png';
import c6 from '../../Assets/Images/c6.png';


class Categ extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      cat_id: "",
      emsg: "",
      pcat: []
    };
  }
  
  async componentDidMount () {
    let user = window.localStorage.getItem("seller");
        user = JSON.parse(window.localStorage.getItem("seller"));
    let cat_id = this.props.match.params.cat_id;
    await Api.get("api/Product/getproduct/"+cat_id)
    // await Api.get("api/ProductCategory/getproductcategoriesandsub")
    .then((resp) => {
      console.log(resp.data.data);
      // let pcat = resp.data.data.items;
      // this.setState({pcat, user});
    }).catch((obj) => {
      if(obj?.response?.data) {
        this.setState({emsg: obj.response.data.message, user});
      }
    });
  }

  render () {
  return (
    <div className="App bga">
      <header className="App-header wel">
        <img src={logo} className='App-logo' />
        <div></div>
      </header>
      <main className='App-body container main-body cr full'>
        <div className='pp-body'>
            <h3 className='mainh b2'>Clothing</h3>
            <div className='cnp-tab alt'>
                <div className='cnp-rw'>
                    <a className='cnp-it n1' href='/tcateg'><span>T-shirts</span><img src={c1} alt='' /></a>
                    <a className='cnp-it n2' href='/tcateg'><span>Jumpers</span><img src={c2} alt='' /></a>
                </div>
                <div className='cnp-rw'>
                    <a className='cnp-it n1' href='/tcateg'><span>Hoodies</span><img src={c3} alt='' /></a>
                    <a className='cnp-it n2' href='/tcateg'><span>Cargos</span><img src={c4} alt='' /></a>
                </div>
                <div className='cnp-rw'>
                    <a className='cnp-it n1' href='/tcateg'><span>Polo shirts</span><img src={c5} alt='' /></a>
                    <a className='cnp-it n2' href='/tcateg'><span>Jackets</span><img src={c6} alt='' /></a>
                </div>
            </div>
        </div>
      </main>
    </div>
  );
  }
}

export default Categ;