import $ from 'jquery';
import Container from './Container';
import Api from '../../Api.js';
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

class Settings extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
        eye1: false,
        eye2: false,
        eye3: false,
        user: null,
        store: null,
        image: null,
        imageData: null,
        emsg: "",
        smsg: "",
        email: "",
        password: "",
        currentPassword: "",
        newPassword: "",
        dcon: false,
        confirmNewPassword: ""
      };
    }
    
    async componentDidMount () {
        let user = window.localStorage.getItem("seller");
            user = JSON.parse(window.localStorage.getItem("seller"));
        this.setState({user});

        await Api.get("api/Seller/getSellerBrand", {"Authorization": 'Bearer '+user?.token})
        .then((resp) => {
            let store = resp.data.data;
            this.setState({store});
        }).catch((obj) => {
            this.errorHandle(obj);
        });
    }

    errorHandle = (obj) => {
        let emsg = "An error occurred";
        if(obj?.response) {
            emsg = obj.response.data.message || emsg;
            this.setState({emsg, loading: false});
        } else {
            this.setState({emsg, loading: false});
        }
    }

    updateBrandLogo = async (rem = true) => {
        let { imageData, image, user } = this.state;
        if(user) {
            this.setState({emsg: ''});
            if(image && imageData && rem) {
                var c = document.createElement('canvas');
                var img = document.getElementById('ulogo-preview');
                c.height = img.naturalHeight;
                c.width = img.naturalWidth;
                var ctx = c.getContext('2d');
                ctx.drawImage(img, 0, 0, c.width, c.height);
                var base64String = c.toDataURL().replace('data:', '').replace(/^.+,/, ''); // 'image/png'
                await Api.put({
                    "filename": imageData.name,
                    "mimeType": imageData.type,
                    "base64Content": base64String
                }, "api/Seller/updateBrandLogo", {"Authorization": 'Bearer '+user?.token
                }).then((resp) => {
                    window.location.href = '';
                }).catch((obj) => {
                    this.errorHandle(obj);
                });
            } else {
                await Api.put({
                    "filename": "",
                    "mimeType": "",
                    "base64Content": ""
                }, "api/Seller/updateBrandLogo", {"Authorization": 'Bearer '+user?.token
                }).then((resp) => {
                    window.location.href = '';
                }).catch((obj) => {
                    if(!obj.response) { this.setState({emsg: "Network error", smsg: "", loading: false}); return false; }
                    this.setState({emsg: obj.response.data.message, smsg: ""});
                });
            }
        }
    }

    updateBrandDetails = async (rem = true) => {
        let brandName = $("#brandName").val();
        let brandDescription = $("#brandDescription").val();
        let storeUrl = $("#storeUrl").val();

        let { user } = this.state;

        if(user) {
            this.setState({emsg: '', smsg: ""});
            await Api.post({
                "brandName": brandName,
                "brandDescription": brandDescription,
                "storeUrl": storeUrl
              }, "api/Seller/updateBrandDetails", {"Authorization": 'Bearer '+user?.token
            }).then((resp) => {
                window.location.href = '';
            }).catch((obj) => {
                if(!obj.response) { this.setState({emsg: "Network error", smsg: "", loading: false}); return false; }
                this.setState({emsg: obj.response.data.message, smsg: ""});
            });
        }
    }

    delAcc = async () => {
        let { user, dcon } = this.state;
        let dtype = dcon;
        if(user && dcon) {
            this.setState({emsg: '', smsg: ""});
            await Api.post(dtype == 2 ? {action: 1, customerId: user?.userId} : {}, dtype == 2 ? "api/Customer/activateOrDeativate" : "api/Auth/pauseSite", {"Authorization": 'Bearer '+user?.token
            }).then((resp) => {
                window.location.href = '';
                this.setState({emsg: "", smsg: "", dcon: false});
            }).catch((obj) => {
                if(!obj.response) { this.setState({emsg: "Network error", smsg: "", loading: false}); return false; }
                this.setState({emsg: obj.response.data.message, smsg: "", dcon: false});
            });
        }
    }

    updatePassword = async () => {
        let { user, currentPassword, newPassword, confirmNewPassword } = this.state;

        if(user) {
            this.setState({emsg: ''});
            await Api.put(
                {"currentPassword": currentPassword, "newPassword": newPassword, "confirmNewPassword": confirmNewPassword},
                "api/Seller/updatePassword", {"Authorization": 'Bearer '+user?.token
            }).then((resp) => {
                this.setState({smsg: "Password updated successfully.", currentPassword: "", newPassword: "", confirmNewPassword: "", emsg: ""});
                setTimeout(() => {
                    window.location.href = '';
                }, 750);
            }).catch((obj) => {
                if(!obj.response) { this.setState({emsg: "Network error", smsg: "", loading: false}); return false; }
                this.setState({emsg: obj.response.data.message, smsg: ""});
            });
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value.trim(),
            emsg: "",
            smsg: ""
        });
    };

    render () {
        let props = this.props;
        let { eye1, eye2, eye3, emsg, smsg, store, image, currentPassword, newPassword, confirmNewPassword, pop, dcon } = this.state;
        return (
            <Container page="settings">
                <div className="pagetitle">
                    <div className="navrw">
                        <button className='mob-hamburg for-mobile'>
                            <i className="bi bi-list toggle-sidebar-btn"></i>
                        </button>
                        <h1>Brand settings</h1>
                    </div>
                    {dcon ?
                        <div className="con-pop-up">
                            <div className="con-pop">
                                <strong>{dcon == 2 ? "Delete" : "Pause"} Confirmation</strong>
                                <p style={{textAlign: "center"}}>Are you sure{dcon == 2 ? " you want to delete your account" : ""}?</p>
                                <div className="con-btns">
                                    <button className="ok" onClick={() => {
                                        this.delAcc();
                                    }}>YES</button>
                                    <button className="cancel" onClick={() => {
                                        this.setState({dcon: false});
                                    }}>Cancel</button>
                                </div>
                            </div>
                        </div> : false}
                </div>
                
                {emsg.length > 0 && smsg.length == 0 ? (
                    <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">{emsg}<button type="button" class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                ) : smsg.length ? (
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">{smsg}<button type="button" className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>) : null}

                <div className='sub-section'>
                    <div className="form-group">
                        <label>Brand name</label>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Brand name" id="brandName" defaultValue={store ? store.name : ""} />
                            {/* <button className="btn btn-success msa" onClick={this.updateBrandDetails}>Save</button> */}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Brand description</label>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Brand description" id="brandDescription" defaultValue={store ? store.description : ""} />
                            {/* <button className="btn btn-success msa" onClick={this.updateBrandDetails}>Save</button> */}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Brand URL</label>
                        <div className="input-group mb-3">
                            <span className="input-group-text" style={{color: "#12B196", fontWeight: "bold"}}>https://</span>
                            <input type="text" className="form-control" placeholder="Brand url" id="storeUrl" defaultValue={store ? store.url : ""} />
                            <span className="input-group-text" style={{color: "#12B196", fontWeight: "bold"}}>brandinit.com</span>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className='row'>
                            <div className='col-lg-12 mt-3'>
                                <button className="btn btn-success msa alt" onClick={this.updateBrandDetails}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>

                <form style={{display: "none"}} method="post" enctype="multipart/form-data">
                    <input type="file" id="logoup" accept="image/png, image/jpeg" onChange={(e) => {
                        this.setState({image: URL.createObjectURL($(e.target)[0].files[0]), imageData: $(e.target)[0].files[0]});
                        setTimeout(() => {
                            this.updateBrandLogo();
                        }, 500);
                    }} />
                </form>
                
                <div className='sub-section'>
                    <div className="form-group">
                    {/* <label className='dlabel'><b>Brand logo</b><span className='rspan' style={{fontSize: "14px"}}><i className='bi bi-info-circle-fill'></i>&nbsp;&nbsp;All changes will need to be approved and should meet the criteria</span></label> */}
                        <div className="input-group mb-3 fl-rw">
                            <img className='slogo-rw alt' src={image ? image : (store ? store.logo.imageUrl : "")} alt='' id="ulogo-preview" />
                            <div>
                                <button className="btn btn-light bli" onClick={() => {
                                    $("#logoup").click();
                                }}>Change logo</button>
                                {/* <button className="btn btn-light bli dl" onClick={() => {
                                    this.updateBrandLogo(false);
                                }}>Remove logo</button> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='sub-section'>
                    <div className="form-group">
                        <label><b>Account email</b></label>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Email" defaultValue={store ? store.email : ""} />
                            <button className="btn btn-success msa">Send verification</button>
                        </div>
                    </div>
                </div> */}

                <div className='sub-section'>
                    <div className="form-group">
                        <label><b>Change password</b></label>
                        <div className='row'>
                            <div className='col-lg-12 mt-3'>
                                <div className='row'>
                                    <div className='col-md-6' style={{position: "relative", minHeight: "48px"}}>
                                        <input type={eye1 ? "text" : "password"} className="form-control" placeholder="Current password" name='currentPassword' onChange={this.handleInputChange} />
                                        <span className='eye-tog' onClick={() => { this.setState({eye1: !eye1}); }}>{eye1 ? <i className="ri-eye-line"></i> : <i className="ri-eye-off-line"></i>}</span>
                                    </div>
                                    <div className='col-md-6'>
                                        <span>Can't remember the password you chose when registering your account? <a className='rsv' href='/reset-password'>Reset it via email</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 mt-3'>
                                <div className='row'>
                                    <div className='col-md-6' style={{position: "relative", minHeight: "48px"}}>
                                        <input type={eye2 ? "text" : "password"} className="form-control" placeholder="New password" name='newPassword' onChange={this.handleInputChange} />
                                        <span className='eye-tog' onClick={() => { this.setState({eye2: !eye2}); }}>{eye2 ? <i className="ri-eye-line"></i> : <i className="ri-eye-off-line"></i>}</span>
                                    </div>
                                    <div className='col-md-6' style={{position: "relative", minHeight: "48px"}}>
                                        <input type={eye3 ? "text" : "password"} className="form-control" placeholder="Confirm new password" name='confirmNewPassword' onChange={this.handleInputChange} />
                                        <span className='eye-tog' onClick={() => { this.setState({eye3: !eye3}); }}>{eye3 ? <i className="ri-eye-line"></i> : <i className="ri-eye-off-line"></i>}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(currentPassword.length && newPassword.length && confirmNewPassword.length) ? (
                            <div className="form-group">
                                <button className="btn btn-success msa flx" onClick={this.updatePassword}>Update</button>
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className='sub-section'>
                    <div className="form-group">
                        <label><b>Pause your site</b></label>
                        <div className='row'>
                            <div className='col-lg-12 mt-3'>
                                <span className='rspan'>Make your store inactive during content, product, design, or other updates. When on, you can edit and access your store, but store visitors will see the message: "Down for maintenance, we'll be back soon!".</span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 mt-3'>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" defaultChecked={false} onChange={() => {
                                        this.setState({dcon: 1});
                                    }} />
                                    <button className='skk' style={{cursor: "pointer"}}>Pause your site</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sub-section'>
                    <div className="form-group">
                        <label><b>Delete your account</b></label>
                        <div className='row'>
                            <div className='col-lg-12 mt-3'>
                                <span className='trsd'>Your account and order information and any uploaded files will be permanently removed from Brandin’ It. These can't be recovered. You will lose access to your account and any uploaded files. These can't be recovered.</span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 mt-3'>
                                <button className='skk-d' style={{cursor: "pointer"}} onClick={() => {
                                    this.setState({dcon: 2});
                                }}>Delete account</button>
                            </div>
                        </div>
                    </div>
                </div>

            </Container>
        );
    }

}

export default Settings;