import Container from './Container';
import Api from '../../Api.js';
import Products from './products';
import Orders from './orders';
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import tfl from '../../Assets/Images/tfl.svg';
import $ from 'jquery';


class Home extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
        user: null,
        emsg: '',
        ps: "*",
        ab: "*",
        tp: "*"
      };
    }

    errorHandle = (obj) => {
        let emsg = "An error occurred";
        if(obj?.response) {
            emsg = obj.response.data.message || emsg;
            this.setState({emsg, loading: false});
        } else {
            this.setState({emsg, loading: false});
        }
    }

    async componentDidMount () {
        let user = window.localStorage.getItem("seller");
            user = JSON.parse(window.localStorage.getItem("seller"));
        this.setState({user});
        await Api.get("api/Seller/sellerdashboard", {"Authorization": 'Bearer '+user?.token})
        .then((resp) => {
            let response = resp.data.data;
            this.setState({ps: response.purchases, ab: "£0", tp: "£0"})
        }).catch((obj) => {
            this.errorHandle(obj);
        });
        this.otherearnings(user);
    }

    async otherearnings (user) {
        this.setState({emsg: ""});
        await Api.get("api/Wallet/otherearnings", {"Authorization": 'Bearer '+user?.token})
        .then((resp) => {
            let data = resp.data.data;
            this.setState({tp: data.paidIntoAccount, ab: data.earnings});
        }).catch((obj) => {
            this.errorHandle(obj);
        });
    }

    render () {
        let props = this.props;
        let {ps, ab, tp} = this.state;
        return (
            <Container page="home">
                <div className="pagetitle">
                    <div className="navrw">
                        <button className='mob-hamburg for-mobile'>
                            <i className="bi bi-list toggle-sidebar-btn"></i>
                        </button>
                        <h1>Dashboard</h1>
                    </div>
                    <div>
                        <span className="trbl not-mobile"><a href="/training">Go to training videos</a></span>
                        <a className="btn btn-success trb" href="/newProduct"><i className='ri ri-add-circle-fill anpi'></i><span className='anps not-mobile'>Add new product</span></a>
                    </div>
                </div>
                <section className="section dashboard">
                    {/* <div className="yac">
                        <img src={tfl} className='tfl-d' />
                        <span>Your account activation is still in progress</span>
                    </div> */}

                    <div className="wtb">
                        <h5>Welcome to Brandin'it</h5>
                        <span>This is your professional dashboard with all the details of your brand</span>
                        <a href="/training" className='for-mobile' style={{marginTop: "10px", color: "rgb(18, 177, 150)", textDecoration: "none"}}><i className='ri ri-play-circle-fill' style={{fontSize: "25px", marginTop: "-5px"}}></i>&nbsp;Go to training videos</a>
                    </div>

                    <div className="row">

                        <div className="col-lg-12">
                            <div className="row">

                                <div className="col-xxl-4 col-md-12">
                                    <div className="card info-card _1">
                                        <div className="card-body">
                                            <h5 className="card-title">Products Sold</h5>
                                            <div className="d-flex align-items-center">
                                                <h6>{ps}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xxl-4 col-xl-12">
                                    <div className="card info-card _3">
                                        <div className="card-body">
                                            <h5 className="card-title">Account Balance</h5>
                                            <div className="d-flex align-items-center">
                                                <h6>{ab}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xxl-4 col-xl-12">
                                    <div className="card info-card _4">
                                        <div className="card-body">
                                            <h5 className="card-title">Total Profit</h5>
                                            <div className="d-flex align-items-center">
                                                <h6>{tp}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Products summarize={true} />
                    <Orders summarize={true} />
                </section>
            </Container>
        );
    }
}

export default Home;