import '../../App.scss';
import logo from '../../Assets/Images/logo.svg';
import bc from '../../Assets/Images/bc.svg';
import we1 from '../../Assets/Images/we-2.png';

function Congrats () {
  return (
    <div className="App">
      <header className="App-header wel">
          <a className='logo-hl' href="https://brandinit.com" target="_blank">
            <img src={logo} className='App-logo' />
          </a>
        <div></div>
      </header>
      <div className='wec0 alt'>
        <h1 className='mainh b2'>Congratulations, <br />you have completed the <br />educational section</h1>
        <p className='clst'>
          Now you can create<br />
          <span>&nbsp;-&nbsp;&nbsp; Your Brand Name</span>
          <span>&nbsp;-&nbsp;&nbsp; Your Logo</span>
          <span>&nbsp;-&nbsp;&nbsp; Your Product</span><br />
          <span>And finally, start selling!</span>
        </p>
        <div className='gsv-cover'>
            <a className='btn-next' href='/create'>Let's Go</a>
        </div>
      </div>
      <div className='wec1 alt'>
        <img src={we1} alt='' className='we1' />
      </div>
      <img src={bc} className='bc-ro' />
    </div>
  );
}

export default Congrats;