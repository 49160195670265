import '../../App.scss';
import Api from '../../Api.js';
import React, { Component } from 'react';
import logo from '../../Assets/Images/logo.svg';

class CustomProduct extends Component {

  constructor(props) {
    super(props);
    this.state = {
        token: "",
        user: null,
        cat_id: "",
        emsg: "",
        pcat: []
    };
  }
  
  async componentDidMount () {
    let user = window.localStorage.getItem("seller");
        user = JSON.parse(window.localStorage.getItem("seller"));
    let token = user?.token || "";
    
    this.setState({ token });

    window.addEventListener("message", ev => {
      if(ev.data == "saveProduct") {
        window.location.href = user.hasFilledGuardian ? "/home" : "/product_created";
      }
    })
  }

  render () {
    let { token } = this.state;
    return token.length ? (
        <div className="App">
          <header className="App-header wel">
            <a className='logo-hl' href="https://brandinit.com" target="_blank">
              <img src={logo} className='App-logo' />
            </a>
            <div></div>
          </header>
          <iframe src={"https://brandinit-canvas-a7b3470770d9.herokuapp.com/?no_header=true&token="+token} className='onb-can' title=""></iframe>
        </div>
      ) : null;
  }
}

export default CustomProduct;