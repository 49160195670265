import '../../App.scss';
import '../../Mobile.scss';
import logo from '../../Assets/Images/logo.svg';
import bc from '../../Assets/Images/bc.svg';
import we1 from '../../Assets/Images/we-1.png';

function Welcome () {
  return (
    <div className="App">
      <header className="App-header container">
        <a className='logo-hl' href="https://brandinit.com" target="_blank">
          <img src={logo} className='App-logo' />
        </a>
        <div></div>
      </header>
      <div className='wec0 forwelp'>
        <h1 className='mainh b2'>Welcome <br />to Brandin’It</h1>
        <p>Hey, awesome creators! Welcome to Brandin’It, the coolest spot where your ideas become a brand. Get ready to unleash your creativity and turn your dreams into reality - because at Brandin’It, it's all about YOU!</p>
        {/* <p>With a focus on combining education and entrepreneurship, brandin’ it is a unique platform that provides equal access to all children/teenagers regardless of economic background.</p>
        <p>The aim is to educate and equip young people with the skills that they need to build, manage and market a business. To inspire creativity and ambition in the next generation.</p> */}
        <div className='gsv-cover'>
            <a className='btn-next' href='/getting-started'>Lets Start</a>
        </div>
      </div>
      <div className='wec1'>
        <img src={we1} alt='' className='we1' />
      </div>
      <img src={bc} className='bc-ro' />
      <marquee className='bc-ma' loop>What are you waiting for, lets get creating! It's Your Brand Init!</marquee>
    </div>
  );
}

export default Welcome;