import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SignIn from './Pages/Onboarding/sign-in';
import SignUp from './Pages/Onboarding/sign-up';
import Welcome from './Pages/Onboarding/welcome';
import Congrats from './Pages/Onboarding/congrats';
import GettingStarted from './Pages/Onboarding/getting-started';
import Create from './Pages/Onboarding/create';
import CreateLogo from './Pages/Onboarding/create_logo';
import UploadLogo from './Pages/Onboarding/upload_logo';
import NewProduct from './Pages/Onboarding/new_product';
import Categ from './Pages/Onboarding/categ';
import TCateg from './Pages/Onboarding/tcateg';
import YourProduct from './Pages/Onboarding/your_product';
import ProductDetail from './Pages/Onboarding/product_detail';
import PriceSetting from './Pages/Onboarding/price_setting';
import ProductCreated from './Pages/Onboarding/product_created';
import customProduct from './Pages/Onboarding/customize_product';

import Home from './Pages/Dashboard/home';
import Orders from './Pages/Dashboard/orders';
import Products from './Pages/Dashboard/products';
import NewProductMain from './Pages/Dashboard/newProduct';
import Training from './Pages/Dashboard/training';
import Analytics from './Pages/Dashboard/analytics';

import Earnings from './Pages/Dashboard/earnings';
import Customers from './Pages/Dashboard/customers';
import Discounts from './Pages/Dashboard/discounts';
import Settings from './Pages/Dashboard/settings';
import Coupons from './Pages/Dashboard/coupons';
import NewCoupon from './Pages/Dashboard/NewCoupon';
import NewSale from './Pages/Dashboard/NewSale';
import EmailSettings from './Pages/Dashboard/email_settings';
import AddNewProduct from './Pages/Dashboard/AddNewProduct';
import Product from './Pages/Onboarding/product';
import CustomProduct from './Pages/Onboarding/customize_product';
import ResetPassword from './Pages/Onboarding/reset-password';

export default props => (
    <Switch>
        <Route exact path='/reset-password' component={ResetPassword} />
        <Route exact path='/resetpassword' component={ResetPassword} />
        <Route exact path='/sign-in' component={SignIn} />
        <Route exact path='/sign-up' component={SignUp} />
        <Route exact path='/guardian' component={SignUp} />
        <Route exact path='/getting-started' component={GettingStarted} />
        <Route exact path='/welcome' component={Welcome} />
        <Route exact path='/congrats' component={Congrats} />
        <Route exact path='/create' component={Create} />
        <Route exact path='/create_logo' component={CreateLogo} />
        <Route exact path='/upload_logo' component={UploadLogo} />
        {/* <Route exact path='/new_product' component={NewProduct} /> */}
        <Route exact path='/customProduct' component={customProduct} />
        <Route exact path='/newProduct' component={NewProductMain} />
        <Route exact path='/createProduct' component={CustomProduct} />
        {/* <Route exact path='/categ/:cat_id' component={Categ} /> */}
        {/* <Route exact path='/product/:cat_id' component={Product} /> */}
        {/* <Route exact path='/tcateg' component={TCateg} /> */}
        <Route exact path='/your_product' component={YourProduct} />
        {/* <Route exact path='/price_setting' component={PriceSetting} /> */}
        <Route exact path='/product_created' component={ProductCreated} />
        <Route exact path='/home' component={Home} />
        <Route exact path='/orders' component={Orders} />
        <Route exact path='/products' component={Products} />
        <Route exact path='/training' component={Training} />
        <Route exact path='/analytics' component={Analytics} />
        <Route exact path='/earnings' component={Earnings} />
        <Route exact path='/customers' component={Customers} />
        <Route exact path='/discounts' component={Discounts} />
        <Route exact path='/settings' component={Settings} />
        <Route exact path='/coupons' component={Coupons} />
        <Route exact path='/new_coupon' component={NewCoupon} />
        <Route exact path='/new_sale' component={NewSale} />
        <Route exact path='/product_detail' component={ProductDetail} />
        <Route exact path='/add_new_product' component={AddNewProduct} />
        <Route exact path='/email_settings' component={EmailSettings} />
        <Route path='/' component={SignIn} />
    </Switch>
);


// /api/ProductCategory/getproductcategories
// /api/ProductCategory/getproductcategoriesandsub
// /api/Product/getproduct/{subCategoryId}