import Container from './Container';
import { withRouter } from "react-router-dom";
import Api from '../../Api.js';
import $ from "jquery";
import React, { Component } from 'react';
import NewProduct from '../Onboarding/new_product';

class NewProductMain extends Component {

  constructor(props) {
    super(props);
    this.state = {
        token: "",
        user: null,
        cat_id: "",
        emsg: "",
        pcat: [],
        plen: 0,
        products: []
    };
  }
  
  async componentDidMount () {
    let user = window.localStorage.getItem("seller");
        user = JSON.parse(window.localStorage.getItem("seller"));
    let token = user?.token || "";
    this.setState({ token, user });
    window.addEventListener("message", ev => {
      if(ev.data == "saveProduct") {
        window.location.href = "/products";
      }
      if(ev.data == "loadedProduct") {
        $(".dash-can").addClass("pa");
      }
    });
    this.getProducts(user);
  }

  getProducts = async (user) => {
      await Api.get("api/Product/getsellerproducts?SellerId="+user?.userId, {"Authorization": 'Bearer '+user?.token})
      .then((resp) => {
        let products = resp.data.data;
        // console.log(products.items, "<<<<<<<<<<<<");
        this.setState({plen: products.items.length});
      }).catch((obj) => {
        if(obj?.response) {
            this.setState({emsg: obj.response.data.message});
        } else {
            this.setState({emsg: "An error occurred"});
            console.log(obj, "==========");
        }
      });
  }

  render () {
    let { token, plen } = this.state;
    return token.length ? (
      <Container page="newProduct">
        <div className="pagetitle">
          <div className="navrw">
              <button className='mob-hamburg for-mobile'>
                  <i className="bi bi-list toggle-sidebar-btn"></i>
              </button>
              <h1>Add new product</h1>
          </div>
        </div>
        {plen >= 5 ? <h5><i class="bi bi-info-circle"></i> Maximum number of products <b>5</b> reached.</h5> : <iframe src={"https://brandinit-canvas-a7b3470770d9.herokuapp.com/?no_header=true&token="+token} className="dash-can" title=""></iframe>}
      </Container>
      ) : null;
  }
}

export default NewProductMain;