import '../../App.scss';
import logo from '../../Assets/Images/logo.svg';
import cpie from '../../Assets/Images/cpie.svg';
import tm from '../../Assets/Images/tm.svg';
import t1 from '../../Assets/Images/t1.png';
import t2 from '../../Assets/Images/t2.png';
import t3 from '../../Assets/Images/t3.png';

function Tcat () {
    return (
        <div className='cnp-tab alt-2'>
            <div className='cnp-rw'>
                <a className='cnp-it t1' href='/your_product'>
                    <img src={t1} alt='' />
                    <strong>Unisex Organic light t-shirt</strong>
                    <div>
                        <span>Sizes: S - 3XL</span>
                        <span><img src={cpie} alt='' /><span>Colours: 6</span></span>
                    </div>
                    <div className='alt'>
                        <b>Starting from £9.25</b>
                        <span><img src={tm} alt='' /> Delivered within two weeks</span>
                    </div>
                </a>
                <a className='cnp-it t2' href='/your_product'>
                    <img src={t2} alt='' />
                    <strong>Female Organic t-shirt</strong>
                    <div>
                        <span>Sizes: S - 3XL</span>
                        <span><img src={cpie} alt='' /><span>Colours: 6</span></span>
                    </div>
                    <div className='alt'>
                        <b>Starting from £9.25</b>
                        <span><img src={tm} alt='' /> Delivered within two weeks</span>
                    </div>
                </a>
                <a className='cnp-it t3' href='/your_product'>
                    <img src={t3} alt='' />
                    <strong>Unisex Organic light t-shirt</strong>
                    <div>
                        <span>Sizes: S - 3XL</span>
                        <span><img src={cpie} alt='' /><span>Colours: 6</span></span>
                    </div>
                    <div className='alt'>
                        <b>Starting from £9.25</b>
                        <span><img src={tm} alt='' /> Delivered within two weeks</span>
                    </div>
                </a>
            </div>
        </div>
    )
}

function TCateg () {
  return (
    <div className="App bga">
      <header className="App-header wel">
        <img src={logo} className='App-logo' />
        <div></div>
      </header>
      <main className='App-body container main-body cr'>
        <div className='pp-body'>
            <h3 className='mainh b2'>T-Shirts</h3>
            <div className='row'>
                <div className='col-md-9'>
                    <Tcat />
                    <Tcat />
                    <Tcat />
                </div>
                <div className="col-md-3">
                    <div className="sub-section balt">
                        <div className="form-group">
                            <b>Cost Price:</b>
                            <span className='_14'>How much it costs us to make it.</span>
                            <br />
                            <b>Delivery Time:</b>
                            <span className='_14'>How long it takes for your customer to receive your products.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </main>
    </div>
  );
}

export default TCateg;