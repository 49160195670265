import Container from './Container';
import Api from '../../Api.js';
import $ from 'jquery';
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import pdc from '../../Assets/Images/pdc.svg';

function PTable ({props, products, setPop, toggleV, deleteP, uploadP}) {
    return (
        <section className="section dashboard">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body for-table">
                                    <table className="table b-table">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Category</th>
                                                <th>Visibility</th>
                                                <th>Price</th>
                                                <th style={{width: "150px"}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {products.length ?
                                                products.map((prd) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <div className='pdc'>
                                                                    <img
                                                                        src={prd.productImages.length ? (prd.productImages[0].backgroundImageUrl) : null}
                                                                        style={prd.productImages.length ? null : {background: "#DDD"}} alt=''
                                                                        onClick={() => {
                                                                            setPop(prd.productImages[0].backgroundImageUrl);
                                                                        }}
                                                                    />
                                                                    <div>
                                                                        <b>{prd.productName}</b>
                                                                        <span>{prd.date}</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td><span className='mt10'>{prd.productCategory.categoryName}</span></td>
                                                            <td>
                                                                {/* {prd.isVisible ? "Visible" : "Not visible"} */}
                                                                <div class="form-check form-switch mt10">
                                                                    <input class="form-check-input" type="checkbox" defaultChecked={prd.isVisible} onChange={(e) => {
                                                                        toggleV(prd.id, $(e.target).is(":checked"));
                                                                    }} />
                                                                </div>
                                                            </td>
                                                            <td><span className='mt10'>£{prd.price}</span></td>
                                                            <td style={{width: "200px"}}>
                                                                <button className='del-p mt10' style={{marginRight: "10px"}} onClick={() => {
                                                                    uploadP(prd.id, prd.price);
                                                                }}>Edit</button>
                                                                <button className='del-p mt10' onClick={() => {
                                                                    deleteP(prd.id);
                                                                }}>Delete</button></td>
                                                        </tr>
                                                    )
                                                })
                                            :
                                                <tr>
                                                    <td colSpan={5}>
                                                        <h4 className='opac-2'><center><i>Empty</i></center></h4>
                                                    </td>
                                                </tr>
                                            }
                                            {/* {[...Array(sumrz ? 6 : 50)].map((e, i) => <Tbody key={'trow-'+i} />)} */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function Capsule (props) {
    let sumrz = props.props?.summarize || "";
    return sumrz ? (
        <>{props.children}</>
    ) : (
        <Container page="products">{props.children}</Container>
    )
}


class Products extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
        user: null,
        emsg: '',
        pop: null,
        dcon: false,
        del_id: null,
        p_id: null,
        filter: '',
        ucon: false,
        products: []
      };
    }
    
    async componentDidMount () {
        let user = window.localStorage.getItem("seller");
            user = JSON.parse(window.localStorage.getItem("seller"));
        this.setState({user});
        this.getProducts(user);
    }

    getProducts = async (user) => {
        await Api.get("api/Product/getsellerproducts?SellerId="+user?.userId, {"Authorization": 'Bearer '+user?.token})
        .then((resp) => {
            let products = resp.data.data;
            this.setState({products: products.items});
        }).catch((obj) => {
            if(obj?.response) {
                this.setState({emsg: obj.response.data.message});
            } else {
                this.setState({emsg: "An error occurred"});
                console.log(obj, "==========");
            }
        });
    }

    toggleV = async (id, v) => {
        let { user } = this.state;
        await Api.put({productId: id, isVisible: v}, "api/Product/updateproductvisibility/"+id+"/"+v, {"Authorization": 'Bearer '+user?.token})
        .then((resp) => {});
    }

    uploadPrice = async () => {
        let { user, p_id, p_val } = this.state;
        await Api.put({price: Number(p_val)}, "api/Product/updateproductprice/"+p_id, {"Authorization": 'Bearer '+user?.token})
        .then((resp) => {
            this.setState({p_id: null, p_val: null, ucon: false});
            window.location.href = "";
        })
        .catch((err) => {
            console.log(err.response.data, "---");
        });
    }

    deleteProduct = async () => {
        let { user, del_id } = this.state;
        await Api.post({productId: del_id}, "api/Product/deleteproduct/"+del_id, {"Authorization": 'Bearer '+user?.token})
        .then((resp) => {
            this.setState({del_id: null, dcon: false});
            window.location.href = "";
        });
    }

    render () {
        let props = this.props;
        let { products, pop, dcon, ucon, p_id, p_val, filter } = this.state;
        let sumrz = props?.summarize || "";

        if(filter.length) {
            let regx = new RegExp(filter, "i");
            products = products.filter((pd) => regx.test(pd.productName) || regx.test(pd.productCategory.categoryName));   
        }

        return (
            <Capsule props={props}>
                <div className="pagetitle">
                    <div className="navrw">
                        {sumrz ? null : <button className='mob-hamburg for-mobile'>
                            <i className="bi bi-list toggle-sidebar-btn"></i>
                        </button>}
                        <h1>Products</h1>
                    </div>
                    {sumrz ? (<div><span className="trbl"><a href="/products">View all</a></span></div>) : (<div>
                        <a className="btn btn-success trb" href="/newProduct"><i className='ri ri-add-circle-fill anpi'></i><span className='anps not-mobile'>Add new product</span></a>
                    </div>)}
                    {dcon ?
                        <div className="con-pop-up">
                            <div className="con-pop">
                                <strong>Delete Confirmation</strong>
                                <p>Are you Sure?</p>
                                <div className="con-btns">
                                    <button className="ok" onClick={() => {
                                        this.deleteProduct();
                                    }}>YES</button>
                                    <button className="cancel" onClick={() => {
                                        this.setState({del_id: null, dcon: false});
                                    }}>Cancel</button>
                                </div>
                            </div>
                        </div> : ucon ? (
                            <div className="con-pop-up">
                                <div className="con-pop">
                                    <strong>Edit product price</strong>
                                    <input type='number' onChange={(e) => {
                                        this.setState({p_val: e.target.value});
                                    }} defaultValue={p_val} style={{margin: "10px", width: "100%", borderRadius: "1em", border: "1px solid #ddd", padding: "5px 10px"}} />
                                    <div className="con-btns">
                                        <button className="ok" onClick={() => {
                                            this.uploadPrice();
                                        }}>UPDATE</button>
                                        <button className="cancel" onClick={() => {
                                            this.setState({p_id: null, p_val: null, ucon: false});
                                        }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        ) : false}
                </div>
                <div className='filter-section'>
                    <div class="iw_fill au">
                        <input type="search" placeholder='Search by product name' class="form-control" onChange={(e) => { this.setState({filter: e.target.value}); }} />
                        <span className='sp_i'><i className='bx bx-search-alt'></i></span>
                    </div>
                </div>
                <PTable
                    props={props}
                    products={products}
                    setPop={(p) => {
                        this.setState({pop: p});
                    }}
                    toggleV={this.toggleV}
                    deleteP={(id) => {
                        this.setState({del_id: id, dcon: true})
                    }}
                    uploadP={(id, pr) => {
                        this.setState({p_id: id, p_val: pr, ucon: true})
                    }}
                />
                {pop ?
                    <div className='img-pop-up' onClick={(e) => {
                        if($(e.target).hasClass("img-pop-up")) {
                            this.setState({pop: null});
                        }
                    }}>
                        <img src={pop} alt='' />
                    </div>
                : null}
            </Capsule>
        );
    }
}

export default Products;