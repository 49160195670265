import Container from './Container';
import Api from '../../Api.js';
import { withRouter } from "react-router-dom";
import React, { Component } from 'react';

function OTable ({orders}) {
    return (
        <section className="section dashboard">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body for-table">
                                    <table className="table b-table">
                                        <thead>
                                            <tr>
                                            <th>Date</th>
                                            <th>Product</th>
                                            <th>Order number</th>
                                            <th>Customer name</th>
                                            <th>Price</th>
                                            <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders.length ?
                                                orders.map((ord) => {
                                                    return (
                                                        <tr className={'tbtr order-row-'+ord.orderStatus}>
                                                            <td><span>{ord.date}</span></td>
                                                            <td><span>{ord.orderItems[0].productName}</span></td>
                                                            <td><span>{ord.orderNumber}</span></td>
                                                            <td><span>{ord.customerFullName}</span></td>
                                                            <td><span>£{ord.totalPrice}</span></td>
                                                            <td><span><b>{ord.orderStatusName}</b></span></td>
                                                        </tr>
                                                    )
                                                })
                                            :
                                                <tr>
                                                    <td colSpan={6}>
                                                        <h4 className='opac-2'><center><i color='#DDD'>Empty</i></center></h4>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function Capsule (props) {
    let sumrz = props.props?.summarize || "";
    return sumrz ? (
        <>{props.children}</>
    ) : (
        <Container page="orders">{props.children}</Container>
    )
}


class Orders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            active: 0,
            status: 0,
            filter: '',
            orders: []
        };
    }

    async componentDidMount () {
        let user = window.localStorage.getItem("seller");
            user = JSON.parse(window.localStorage.getItem("seller"));
        this.setState({user});
        this.getOrders(user);
    }

    errorHandle = (obj) => {
        let emsg = "An error occurred";
        if(obj?.response) {
            emsg = obj.response.data.message || emsg;
            this.setState({emsg, loading: false});
        } else {
            this.setState({emsg, loading: false});
        }
    }

    getOrders = async (user) => {
        await Api.get("api/Order/getorders?SellerId="+user?.userId, {"Authorization": 'Bearer '+user?.token})
        .then((resp) => {
            let orders = resp.data.data;
            this.setState({orders: orders.items});
        }).catch((obj) => {
            this.errorHandle(obj);
        });
    }

    render () {
        let props = this.props;
        let sumrz = props?.summarize || "";
        let { active, orders, status, filter } = this.state;

        if(status > 0) {
            orders = orders.filter((od) => od.orderStatus == status);   
        }

        if(filter.length) {
            let regx = new RegExp(filter, "i");
            orders = orders.filter((od) => regx.test(od.customerFullName) || regx.test(od.orderItems[0].productName));   
        }

        return (
            <Capsule props={props}>
                <div className="pagetitle">
                    <div className="navrw">
                        {sumrz ? null : <button className='mob-hamburg for-mobile'>
                            <i className="bi bi-list toggle-sidebar-btn"></i>
                        </button>}
                        <h1>Your Orders</h1>
                    </div>
                    {sumrz ? <div>
                        <span className="trbl"><a href="/orders">View all</a></span>
                    </div> :
                    <div className='icrow'>
                        <div class="iw_fill">
                            <input type="search" placeholder='Search for order' class="form-control" onChange={(e) => { this.setState({filter: e.target.value, status: 0}); }} />
                            <span className='sp_i'><i className='bx bx-search-alt'></i></span>
                        </div>
                        {/* <div class="iw_fill">
                            <input type="date" placeholder='Select date range' class="form-control" />
                        </div> */}
                    </div>}
                </div>
                <div className='filter-section not-mobile'>
                    <button type="button" className={"btn btn-secondary suc"+(status == 0 ? " active" : "")+" __"+status} onClick={() => this.setState({status: 0, filter: ''})}>All orders</button>
                    <button type="button" className={"btn btn-secondary suc"+(status == 4 ? " active" : "")+" __"+status} onClick={() => this.setState({status: 4, filter: ''})}>Pending orders</button>
                    <button type="button" className={"btn btn-secondary suc"+(status == 2 ? " active" : "")+" __"+status} onClick={() => this.setState({status: 2, filter: ''})}>Sent orders</button>
                    <button type="button" className={"btn btn-secondary suc"+(status == 1 ? " active" : "")+" __"+status} onClick={() => this.setState({status: 1, filter: ''})}>Orders in progress</button>
                </div>
                <div className='filter-section for-mobile'>
                    <select className='form-control' onChange={(e) => this.setState({status: e.target.value, filter: ''})} value={status}>
                        <option value={0}>All orders</option>
                        <option value={4}>Pending orders</option>
                        <option value={2}>Sent orders</option>
                        <option value={1}>Orders in progress</option>
                    </select>
                </div>
                <OTable orders={orders} />
            </Capsule>
        );
    }
}

export default Orders;