import Container from './Container';
import { withRouter } from "react-router-dom";
import imgP from '../../Assets/Images/img-p.png';
import tgib from '../../Assets/Images/tgib.png';

function AddNewProduct (props) {
  return (
    <Container page="add_new_product">
        <div className="pagetitle smwi">
            <div className="navrw">
                <h1>Add new product</h1>
            </div>
        </div>

        <div className='sub-section smwi'>
            <div className="form-group">
                <label><b>Product preview</b></label>
                <div className='ppv'>
                    <img src={imgP} />
                    <div className='pvd'>
                        <span>Drag & drop images here or <a>Browse on your computer</a></span>
                        <span className='gry'>Recommended image size is 1240px x 930px</span>
                    </div>
                </div>
                <div className='tgib'>
                    <img src={tgib} alt='' />
                    <img src={tgib} alt='' />
                    <img src={tgib} alt='' />
                    <img src={tgib} alt='' />
                </div>
                <div className="input-group flex-e">
                    <button className='btn btn-default b1 alt'>Edit design</button>
                </div>
            </div>
        </div>

        <div className='sub-section smwi'>
            <div className="form-group">
                <label><b>Product details</b></label>
                <div className="form-group">
                    <label>Product name</label>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder="My Fancy Product" />
                    </div>
                    <p className='spt'><span>https/daniellegrey.brandinit.com/p/<span className='rspan'>my-fancy-product</span></span></p>
                </div>
                <label>Product Description</label>
                <div className="input-group mb-3">
                    <textarea className="form-control h200" placeholder="Describe your product in your own words it doesn't have to be too long, keep it simple and easy to read."></textarea>
                </div>
            </div>
        </div>

        <div className='sub-section smwi'>
            <div className="form-group">
                <label><b>Add digital files (optional)</b></label>
                <div className='ppv'>
                    <img src={imgP} />
                    <div className='pvd'>
                        <span>Drag & drop images here or <a>Browse on your computer</a></span>
                        <span className='gry'>Recommended image size is 1240pxx930px</span>
                    </div>
                </div>
            </div>
        </div>

        
        <div className='sub-section smwi alt'>
            <div className="form-group flex-e">
                <button className='btn btn-success w225'>Next</button>
            </div>
        </div>


    </Container>
  );
}

export default AddNewProduct;