import '../../App.scss';
import '../../Dashboard.scss';
import $ from "jquery";
import SideMenu from './SideMenu';
import React, { Component } from 'react';

class Container extends Component {

  constructor(props) {
    super(props);
    this.state = {
        active: 0
    };
  }

  
  async componentDidMount () {
    let seller = window.localStorage.getItem("seller") || null;
    if(!seller) {
      window.location.href = "/sign-in";
    }

    $(document).delegate(".mob-hamburg", "click", (e) => {
      if($("#sidebar").hasClass("show") && $("#sidebar").hasClass("_wt_")) {
        $("#sidebar, .sidebar-shadow").removeClass("show");
        $("#sidebar").removeClass("_wt_");
      } else {
        $("#sidebar, .sidebar-shadow").addClass("show");
        setTimeout(() => {
          $("#sidebar").addClass("_wt_");
        }, 1000);
      }
    });

    $(document).delegate(".sidebar-shadow", "click", (e) => {
      $("#sidebar, .sidebar-shadow").removeClass("show");
      $("#sidebar").removeClass("_wt_");
    });
  }

  render () {
    let props = this.props;
    return (
      <div className="App bga afap">
          <SideMenu page={props.page} />
          {props.hamburg ?
            <button className='mob-hamburg flyin'>
              <i className="bi bi-list toggle-sidebar-btn"></i>
            </button> : null}
          <main id="main" className={"main"+" "+props.page+"-page"}>
            {props.children}
          </main>
      </div>
    );
  }
}

export default Container;