import '../../App.scss';
import logo from '../../Assets/Images/logo.svg';
import t1 from '../../Assets/Images/t1.png';
import f from '../../Assets/Images/f.png';
import se from '../../Assets/Images/se.png';
import ib from '../../Assets/Images/ib.png';
import e from '../../Assets/Images/e.png';
import g from '../../Assets/Images/g.png';

function YourProduct () {
  return (
    <div className="App bga">
      <header className="App-header wel">
        <img src={logo} className='App-logo' />
        <div></div>
      </header>
      <main className='App-body container main-body cr'>
        <div className='pp-body'>
            <h4 className='mainh'>Customise your product</h4>
            <div className='gsv-cover cst cu-product'>
              <div className='cu-main'>
                <div className='cu-head'>
                  <div className='_1'>
                    <b>Font print</b>
                    <span>Incl.</span>
                  </div>
                  <div>
                    <b>Back print</b>
                    <span>+ £5.95</span>
                  </div>
                  <div>
                    <b>Outside label</b>
                    <span>+ £5.95</span>
                  </div>
                  <div>
                    <b>Inside label</b>
                    <span>+ £5.95</span>
                  </div>
                  <div>
                    <b>Left sleeve</b>
                    <span>+ £5.95</span>
                  </div>
                  <div>
                    <b>Right sleeve</b>
                    <span>+ £5.95</span>
                  </div>
                </div>
                <div className='cu-body'>
                  <button className='itl-1'><img src={se} /></button>
                  <button className='itl-2'><img src={f} /></button>
                  <img src={ib} className='ib-m' />
                </div>
              </div>
              <div className='cu-right'>
                <h2 className='cu-h'>Create your design</h2>
                <div className='btn-rw'>
                  <button className='btn btn-default'><img src={g} className='' /> Add graphics</button>
                  <button className='btn btn-default'><img src={e} className='' /> Add texts</button>
                </div>
                <b className='cu-sh'>Product info</b>
                <div className='cu-rw'>
                  <img src={t1} className='cu-il' />
                  <span className='cu-ds'>Unisex Organic light t-shirt</span>
                </div>
                <b className='cu-sh'>Print technique</b>
                <div className='cu-rw'>
                  <label><input type='radio' name="print" value="DTG" checked /> Printing (DTG)</label>
                  <label style={{marginLeft: "15px"}}><input type='radio' name="print" value="EMB" /> Embroidery</label>
                </div>
                <b className='cu-sh'>Product colours</b>
                <div className='cu-rw rw'>
                  <div className='clr _1'>&nbsp;</div>
                  <div className='clr _2'>&nbsp;</div>
                  <div className='clr _3'>&nbsp;</div>
                  <div className='clr _4'>&nbsp;</div>
                  <div className='clr _5'>&nbsp;</div>
                  <div className='clr _6'>&nbsp;</div>
                  <div className='clr _7'>&nbsp;</div>
                  <div className='clr _8'>&nbsp;</div>
                  <div className='clr _9'>&nbsp;</div>
                  <div className='clr _10'>&nbsp;</div>
                </div>
                <span className='cut'>Choose up to 5 colour variants</span>
              </div>
            </div>
            <div className='gsv-foot cst'>
                <span>Manufacturing cost</span>
                <h2>£30<small>*</small></h2>
                <p>
                    <small>*</small>
                    <span>
                        Product cost may vary slightly based on your customer's location.<br />
                        Fulfilment charges include the product cost and the shipping paid by your customer. This is collected through the payment method added in Account {'>'} Billing
                    </span>
                </p>
                <a className='btn-next' href='/product_detail'>Continue</a>
            </div>

        </div>
      </main>
    </div>
  );
}

export default YourProduct;