import Container from './Container';
import '../../App.scss';
import GStarted from '../Onboarding/getting-started';

function Training () {

  let url = window.location.search;
  let _s = url.indexOf("?s=") > -1 ? url.replace("?s=", "") : "0";
      _s = Number(_s);
  let nx = _s == 5 ? '/home' : '/training?s='+(_s + 1);

  return (
    <Container hamburg={true}>
      <GStarted summary={true} />
    </Container>
  );
}

export default Training;