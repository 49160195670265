import Container from './Container';
import { withRouter } from "react-router-dom";

function Settings (props) {
  return (
    <Container page="settings">
        <div className="pagetitle">
            <div className="navrw">
                <h1>Email settings</h1>
            </div>
        </div>
        <div className="filter-section mt0"><p>Customise the automated emails that get sent out to your customers after product purchases,<br />newsletter subscriptions and more.</p></div>
        <div className='sub-section'>
            <div className="form-group">
                <label><b>Contact email</b></label>
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Contact email" />
                    <button className="btn btn-success msa">Update</button>
                </div>
            </div>
        </div>

        <div className='sub-section igb'>
            <div className="form-group">
                <label className='laspn'>
                    <span className='rspan'>Type</span>
                    <span className='rspan'>Actions</span>
                </label>
                <div className="input-group mb-3">
                    <div className='fcol'>
                        <strong>Purchase confirmation</strong>
                        <span className='rspan'>Sent to customers after they've placed an order for any store products,<br />with the exception of subscriptions.</span>
                    </div>
                    <button className="btn btn-default msa b1">Edit template</button>
                </div>
                <div className="input-group mb-3">
                    <div className='fcol'>
                        <strong>Item shipped</strong>
                        <span className='rspan'>Sent to your customer when their item has been shipped</span>
                    </div>
                    <button className="btn btn-default msa b1">Edit template</button>
                </div>
                <div className="input-group mb-3">
                    <div className='fcol'>
                        <strong>Bag abandonment</strong>
                        <span className='rspan'>Sent to prospective customers who left checkout page before completing the purchase.</span>
                    </div>
                    <button className="btn btn-default msa b1">Edit template</button>
                </div>
            </div>
        </div>

    </Container>
  );
}

export default Settings;